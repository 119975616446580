.u-section-1 {
  background-image: linear-gradient(0deg, rgba(0,0,0,0.05), rgba(0,0,0,0.05)), url("images/-min-3.jpg");
  background-position: 50% 50%;
}

.u-section-1 .u-sheet-1 {
  min-height: 746px;
}

.u-section-1 .u-text-1 {
  font-size: 4.5rem;
  width: 836px;
  margin: 103px auto 0;
}

.u-section-1 .u-text-2 {
  width: 836px;
  font-size: 1.875rem;
  margin: 20px auto 0;
}

.u-section-1 .u-line-1 {
  width: 150px;
  height: 6px;
  margin: 20px auto 0;
}

.u-section-1 .u-image-1 {
  width: 625px;
  height: 320px;
  margin: 37px auto 49px;
}

@media (max-width: 1199px) {
  .u-section-1 .u-sheet-1 {
    min-height: 615px;
  }

  .u-section-1 .u-text-1 {
    color: rgb(255, 255, 255) !important;
  }
}

@media (max-width: 991px) {
  .u-section-1 .u-sheet-1 {
    min-height: 471px;
  }

  .u-section-1 .u-text-1 {
    font-size: 3.75rem;
    width: 720px;
  }

  .u-section-1 .u-text-2 {
    width: 720px;
    font-size: 1.5rem;
  }
}

@media (max-width: 767px) {
  .u-section-1 .u-sheet-1 {
    min-height: 309px;
  }

  .u-section-1 .u-text-1 {
    width: 540px;
    font-size: 3rem;
  }

  .u-section-1 .u-text-2 {
    width: 540px;
  }

  .u-section-1 .u-image-1 {
    width: 540px;
    height: 276px;
  }
}

@media (max-width: 575px) {
  .u-section-1 .u-sheet-1 {
    min-height: 207px;
  }

  .u-section-1 .u-text-1 {
    font-size: 2.25rem;
    width: 340px;
  }

  .u-section-1 .u-text-2 {
    width: 340px;
  }

  .u-section-1 .u-image-1 {
    width: 340px;
    height: 174px;
  }
}.u-section-2 {
  min-height: 740px;
}

.u-section-2 .u-layout-wrap-1 {
  width: calc(((100% - 1140px) / 2) + 1140px);
  margin: 60px 0 0 auto;
}

.u-section-2 .u-layout-cell-1 {
  min-height: 306px;
}

.u-section-2 .u-container-layout-1 {
  padding: 30px;
}

.u-section-2 .u-image-1 {
  width: 172px;
  height: 138px;
  margin: 0 auto;
}

.u-section-2 .u-layout-cell-2 {
  min-height: 306px;
}

.u-section-2 .u-container-layout-2 {
  padding: 30px;
}

.u-section-2 .u-line-1 {
  height: 3px;
  transform-origin: left center;
  margin: 0 0 0 auto;
}

.u-section-2 .u-text-1 {
  font-size: 2.8125rem;
  margin: 42px -30px 0 0;
}

.u-section-2 .u-layout-cell-3 {
  min-height: 306px;
}

.u-section-2 .u-container-layout-3 {
  padding: 30px 30px 26px;
}

.u-section-2 .u-text-2 {
  font-size: 1.25rem;
  margin: 0 0 0 auto;
}

.u-section-2 .u-layout-cell-4 {
  min-height: 306px;
  background-image: none;
}

.u-section-2 .u-container-layout-4 {
  padding: 30px;
}

.u-section-2 .u-layout-wrap-2 {
  width: 923px;
  margin: 36px auto;
}

.u-section-2 .u-layout-cell-5 {
  min-height: 296px;
}

.u-section-2 .u-container-layout-5 {
  padding: 30px 30px 16px;
}

.u-section-2 .u-image-2 {
  height: 250px;
  width: 204px;
  margin: 0 1px 0 auto;
}

.u-section-2 .u-layout-cell-6 {
  min-height: 296px;
}

.u-section-2 .u-container-layout-6 {
  padding: 30px;
}

.u-section-2 .u-text-3 {
  font-size: 1.25rem;
  margin: 0 auto 0 0;
}

@media (max-width: 1199px) {
  .u-section-2 {
    min-height: 724px;
  }

  .u-section-2 .u-layout-wrap-1 {
    width: calc(((100% - 940px) / 2) + 940px);
  }

  .u-section-2 .u-layout-cell-1 {
    min-height: 221px;
  }

  .u-section-2 .u-layout-cell-2 {
    min-height: 252px;
  }

  .u-section-2 .u-line-1 {
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-2 .u-text-1 {
    font-size: 1.875rem;
    margin-right: 0;
  }

  .u-section-2 .u-layout-cell-3 {
    min-height: 252px;
  }

  .u-section-2 .u-text-2 {
    font-size: 1.125rem;
  }

  .u-section-2 .u-layout-cell-4 {
    min-height: NaNpx;
  }

  .u-section-2 .u-layout-wrap-2 {
    margin-right: calc(((100% - 940px) / 2));
    margin-bottom: 0;
  }

  .u-section-2 .u-layout-cell-5 {
    min-height: 284px;
  }

  .u-section-2 .u-image-2 {
    height: 224px;
    width: 183px;
    margin-right: 0;
    margin-bottom: 14px;
  }

  .u-section-2 .u-layout-cell-6 {
    min-height: 281px;
  }
}

@media (max-width: 991px) {
  .u-section-2 {
    min-height: 778px;
  }

  .u-section-2 .u-layout-wrap-1 {
    margin-top: 0;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-2 .u-layout-cell-1 {
    min-height: 198px;
  }

  .u-section-2 .u-layout-cell-2 {
    min-height: 198px;
  }

  .u-section-2 .u-layout-cell-3 {
    min-height: 100px;
  }

  .u-section-2 .u-text-2 {
    width: auto;
    margin-right: 7px;
    margin-left: 20px;
  }

  .u-section-2 .u-layout-cell-4 {
    min-height: 32px;
  }

  .u-section-2 .u-layout-wrap-2 {
    width: 720px;
    margin-right: auto;
  }

  .u-section-2 .u-layout-cell-5 {
    min-height: 348px;
  }

  .u-section-2 .u-image-2 {
    height: 210px;
    width: 172px;
    margin-right: -4px;
  }

  .u-section-2 .u-layout-cell-6 {
    min-height: 340px;
  }
}

@media (max-width: 767px) {
  .u-section-2 {
    min-height: 1148px;
  }

  .u-section-2 .u-layout-cell-1 {
    min-height: 100px;
    order: 0;
  }

  .u-section-2 .u-container-layout-1 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .u-section-2 .u-layout-cell-2 {
    min-height: 100px;
    order: 1;
  }

  .u-section-2 .u-container-layout-2 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .u-section-2 .u-layout-cell-3 {
    order: 2;
  }

  .u-section-2 .u-container-layout-3 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .u-section-2 .u-layout-cell-4 {
    order: 3;
    min-height: 1440px;
  }

  .u-section-2 .u-container-layout-4 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-2 .u-layout-wrap-2 {
    width: 540px;
    margin-top: 0;
  }

  .u-section-2 .u-layout-cell-5 {
    order: 0;
    min-height: 240px;
  }

  .u-section-2 .u-container-layout-5 {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0;
  }

  .u-section-2 .u-image-2 {
    margin-right: auto;
  }

  .u-section-2 .u-layout-cell-6 {
    min-height: 100px;
    order: 1;
  }

  .u-section-2 .u-container-layout-6 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 575px) {
  .u-section-2 {
    min-height: 1450px;
  }

  .u-section-2 .u-container-layout-1 {
    padding-right: 10px;
    padding-bottom: 50px;
  }

  .u-section-2 .u-container-layout-3 {
    padding-bottom: 20px;
    padding-left: 30px;
  }

  .u-section-2 .u-text-2 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-2 .u-layout-cell-4 {
    min-height: 185px;
  }

  .u-section-2 .u-layout-wrap-2 {
    width: 340px;
    min-height: 400px;
    margin-top: 38px;
    margin-right: calc(((100% - 340px) / 2));
    margin-bottom: 37px;
  }

  .u-section-2 .u-layout-cell-5 {
    min-height: 100px;
  }
}.u-section-3 .u-sheet-1 {
  min-height: 548px;
}

.u-section-3 .u-text-1 {
  margin: 31px auto 0;
}

.u-section-3 .u-list-1 {
  min-height: 488px;
  grid-template-columns: calc(33.3333% - 7px) calc(33.3333% - 7px) calc(33.3333% - 7px);
  grid-gap: 10px 10px;
  margin: -7px auto 0 0;
}

.u-section-3 .u-container-layout-1 {
  padding: 30px 0 30px 30px;
}

.u-section-3 .u-group-1 {
  width: 275px;
  min-height: 194px;
  margin: 0 auto 0 68px;
}

.u-section-3 .u-container-layout-2 {
  padding: 0 30px 30px;
}

.u-section-3 .u-text-2 {
  font-weight: 700;
  margin: 20px -30px 0 0;
}

.u-section-3 .u-text-3 {
  font-size: 1rem;
  margin: 3px -30px 0 0;
}

.u-section-3 .u-image-1 {
  width: 88px;
  height: 88px;
  margin: -179px auto 0 0;
}

.u-section-3 .u-container-layout-3 {
  padding: 30px 0 30px 30px;
}

.u-section-3 .u-group-2 {
  width: 275px;
  min-height: 179px;
  margin: 0 auto 0 68px;
}

.u-section-3 .u-container-layout-4 {
  padding: 0 30px 30px;
}

.u-section-3 .u-text-4 {
  font-weight: 700;
  margin: 20px -30px 0 0;
}

.u-section-3 .u-text-5 {
  font-size: 1rem;
  margin: 3px -30px 0 0;
}

.u-section-3 .u-image-2 {
  width: 88px;
  height: 88px;
  margin: -179px auto 0 0;
}

.u-section-3 .u-container-layout-5 {
  padding: 30px 0 30px 30px;
}

.u-section-3 .u-group-3 {
  width: 275px;
  min-height: 179px;
  margin: 0 auto 0 68px;
}

.u-section-3 .u-container-layout-6 {
  padding: 0 30px 30px;
}

.u-section-3 .u-text-6 {
  font-weight: 700;
  margin: 20px -30px 0 0;
}

.u-section-3 .u-text-7 {
  font-size: 1rem;
  margin: 3px -30px 0 0;
}

.u-section-3 .u-image-3 {
  width: 88px;
  height: 88px;
  margin: -179px auto 0 0;
}

.u-section-3 .u-container-layout-7 {
  padding: 30px 0 30px 30px;
}

.u-section-3 .u-group-4 {
  width: 275px;
  min-height: 179px;
  margin: 0 auto 0 68px;
}

.u-section-3 .u-container-layout-8 {
  padding: 0 30px 30px;
}

.u-section-3 .u-text-8 {
  font-weight: 700;
  margin: 20px -30px 0 0;
}

.u-section-3 .u-text-9 {
  font-size: 1rem;
  margin: 3px -30px 0 0;
}

.u-section-3 .u-image-4 {
  width: 88px;
  height: 88px;
  margin: -179px auto 0 0;
}

.u-section-3 .u-container-layout-7 {
  padding: 30px 0 30px 30px;
}

.u-section-3 .u-group-4 {
  width: 275px;
  min-height: 179px;
  margin: 0 auto 0 68px;
}

.u-section-3 .u-container-layout-8 {
  padding: 0 30px 30px;
}

.u-section-3 .u-text-8 {
  font-weight: 700;
  margin: 20px -30px 0 0;
}

.u-section-3 .u-text-9 {
  font-size: 1rem;
  margin: 3px -30px 0 0;
}

.u-section-3 .u-image-5 {
  width: 88px;
  height: 88px;
  margin: -179px auto 0 0;
}

.u-section-3 .u-container-layout-7 {
  padding: 30px 0 30px 30px;
}

.u-section-3 .u-group-4 {
  width: 275px;
  min-height: 179px;
  margin: 0 auto 0 68px;
}

.u-section-3 .u-container-layout-8 {
  padding: 0 30px 30px;
}

.u-section-3 .u-text-8 {
  font-weight: 700;
  margin: 20px -30px 0 0;
}

.u-section-3 .u-text-9 {
  font-size: 1rem;
  margin: 3px -30px 0 0;
}

.u-section-3 .u-image-6 {
  width: 88px;
  height: 88px;
  margin: -179px auto 0 0;
}

@media (max-width: 1199px) {
  .u-section-3 .u-sheet-1 {
    min-height: 517px;
  }

  .u-section-3 .u-list-1 {
    min-height: 402px;
    margin-bottom: 55px;
    height: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-3 .u-group-1 {
    width: 208px;
    min-height: 251px;
    margin-right: 0;
    margin-left: auto;
  }

  .u-section-3 .u-text-3 {
    width: auto;
  }

  .u-section-3 .u-image-1 {
    margin-top: -236px;
  }

  .u-section-3 .u-group-2 {
    width: 208px;
    min-height: 251px;
    margin-right: 0;
    margin-left: auto;
  }

  .u-section-3 .u-text-5 {
    width: auto;
  }

  .u-section-3 .u-image-2 {
    margin-top: -236px;
  }

  .u-section-3 .u-group-3 {
    width: 208px;
    min-height: 251px;
    margin-right: 0;
    margin-left: auto;
  }

  .u-section-3 .u-text-7 {
    width: auto;
  }

  .u-section-3 .u-image-3 {
    margin-top: -236px;
  }

  .u-section-3 .u-group-4 {
    width: 208px;
    min-height: 251px;
    margin-right: 0;
    margin-left: auto;
  }

  .u-section-3 .u-text-9 {
    width: auto;
  }

  .u-section-3 .u-image-4 {
    margin-top: -236px;
  }

  .u-section-3 .u-group-4 {
    width: 208px;
    min-height: 251px;
    margin-right: 0;
    margin-left: auto;
  }

  .u-section-3 .u-text-9 {
    width: auto;
  }

  .u-section-3 .u-image-5 {
    margin-top: -236px;
  }

  .u-section-3 .u-group-4 {
    width: 208px;
    min-height: 251px;
    margin-right: 0;
    margin-left: auto;
  }

  .u-section-3 .u-text-9 {
    width: auto;
  }

  .u-section-3 .u-image-6 {
    margin-top: -236px;
  }
}

@media (max-width: 991px) {
  .u-section-3 .u-sheet-1 {
    min-height: 808px;
  }

  .u-section-3 .u-list-1 {
    min-height: 693px;
    grid-template-columns: repeat(2, calc(((100% - 720px) / 2)  + 355px));
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-3 .u-text-2 {
    margin-right: 0;
  }

  .u-section-3 .u-text-3 {
    margin-right: 0;
  }

  .u-section-3 .u-text-4 {
    margin-right: 0;
  }

  .u-section-3 .u-text-5 {
    margin-right: 0;
  }

  .u-section-3 .u-text-6 {
    margin-right: 0;
  }

  .u-section-3 .u-text-7 {
    margin-right: 0;
  }

  .u-section-3 .u-text-8 {
    margin-right: 0;
  }

  .u-section-3 .u-text-9 {
    margin-right: 0;
  }

  .u-section-3 .u-text-8 {
    margin-right: 0;
  }

  .u-section-3 .u-text-9 {
    margin-right: 0;
  }

  .u-section-3 .u-text-8 {
    margin-right: 0;
  }

  .u-section-3 .u-text-9 {
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .u-section-3 .u-list-1 {
    grid-template-columns: calc(50% - 6px) calc(50% - 6px);
    margin-top: 0;
    margin-bottom: 20px;
    grid-gap: 12px 12px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-3 .u-container-layout-1 {
    padding-top: 0;
    padding-bottom: 60px;
    padding-left: 10px;
  }

  .u-section-3 .u-group-1 {
    width: 187px;
    min-height: 227px;
  }

  .u-section-3 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-3 .u-image-1 {
    width: 60px;
    height: 60px;
    margin-top: -219px;
  }

  .u-section-3 .u-container-layout-3 {
    padding-top: 0;
    padding-bottom: 60px;
    padding-left: 10px;
  }

  .u-section-3 .u-group-2 {
    width: 187px;
    min-height: 227px;
  }

  .u-section-3 .u-container-layout-4 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-3 .u-image-2 {
    width: 60px;
    height: 60px;
    margin-top: -219px;
  }

  .u-section-3 .u-container-layout-5 {
    padding-top: 0;
    padding-bottom: 60px;
    padding-left: 10px;
  }

  .u-section-3 .u-group-3 {
    width: 187px;
    min-height: 227px;
  }

  .u-section-3 .u-container-layout-6 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-3 .u-image-3 {
    width: 60px;
    height: 60px;
    margin-top: -219px;
  }

  .u-section-3 .u-container-layout-7 {
    padding-top: 0;
    padding-bottom: 60px;
    padding-left: 10px;
  }

  .u-section-3 .u-group-4 {
    width: 187px;
    min-height: 227px;
  }

  .u-section-3 .u-container-layout-8 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-3 .u-image-4 {
    width: 60px;
    height: 60px;
    margin-top: -219px;
  }

  .u-section-3 .u-container-layout-7 {
    padding-top: 0;
    padding-bottom: 60px;
    padding-left: 10px;
  }

  .u-section-3 .u-group-4 {
    width: 187px;
    min-height: 227px;
  }

  .u-section-3 .u-container-layout-8 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-3 .u-image-5 {
    width: 60px;
    height: 60px;
    margin-top: -219px;
  }

  .u-section-3 .u-container-layout-7 {
    padding-top: 0;
    padding-bottom: 60px;
    padding-left: 10px;
  }

  .u-section-3 .u-group-4 {
    width: 187px;
    min-height: 227px;
  }

  .u-section-3 .u-container-layout-8 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-3 .u-image-6 {
    width: 60px;
    height: 60px;
    margin-top: -219px;
  }
}

@media (max-width: 575px) {
  .u-section-3 .u-sheet-1 {
    min-height: 1135px;
  }

  .u-section-3 .u-list-1 {
    min-height: 1066px;
    grid-template-columns: 100%;
    margin-bottom: 10px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-3 .u-container-layout-1 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .u-section-3 .u-group-1 {
    width: 250px;
    min-height: 161px;
  }

  .u-section-3 .u-container-layout-2 {
    padding-bottom: 0;
  }

  .u-section-3 .u-text-2 {
    margin-top: 4px;
  }

  .u-section-3 .u-image-1 {
    margin-top: -168px;
  }

  .u-section-3 .u-container-layout-3 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .u-section-3 .u-group-2 {
    width: 250px;
    min-height: 161px;
  }

  .u-section-3 .u-container-layout-4 {
    padding-bottom: 0;
  }

  .u-section-3 .u-text-4 {
    margin-top: 4px;
  }

  .u-section-3 .u-image-2 {
    margin-top: -168px;
  }

  .u-section-3 .u-container-layout-5 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .u-section-3 .u-group-3 {
    width: 250px;
    min-height: 161px;
  }

  .u-section-3 .u-container-layout-6 {
    padding-bottom: 0;
  }

  .u-section-3 .u-text-6 {
    margin-top: 4px;
  }

  .u-section-3 .u-image-3 {
    margin-top: -168px;
  }

  .u-section-3 .u-container-layout-7 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .u-section-3 .u-group-4 {
    width: 250px;
    min-height: 161px;
  }

  .u-section-3 .u-container-layout-8 {
    padding-bottom: 0;
  }

  .u-section-3 .u-text-8 {
    margin-top: 4px;
  }

  .u-section-3 .u-image-4 {
    margin-top: -168px;
  }

  .u-section-3 .u-container-layout-7 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .u-section-3 .u-group-4 {
    width: 250px;
    min-height: 161px;
  }

  .u-section-3 .u-container-layout-8 {
    padding-bottom: 0;
  }

  .u-section-3 .u-text-8 {
    margin-top: 4px;
  }

  .u-section-3 .u-image-5 {
    margin-top: -168px;
  }

  .u-section-3 .u-container-layout-7 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .u-section-3 .u-group-4 {
    width: 250px;
    min-height: 161px;
  }

  .u-section-3 .u-container-layout-8 {
    padding-bottom: 0;
  }

  .u-section-3 .u-text-8 {
    margin-top: 4px;
  }

  .u-section-3 .u-image-6 {
    margin-top: -168px;
  }
}.u-section-4 .u-sheet-1 {
  min-height: 758px;
}

.u-section-4 .u-image-1 {
  width: 984px;
  height: 714px;
  margin: 22px auto;
}

@media (max-width: 1199px) {
  .u-section-4 .u-sheet-1 {
    min-height: 726px;
  }

  .u-section-4 .u-image-1 {
    width: 940px;
    height: 682px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .u-section-4 .u-sheet-1 {
    min-height: 566px;
  }

  .u-section-4 .u-image-1 {
    width: 720px;
    height: 522px;
  }
}

@media (max-width: 767px) {
  .u-section-4 .u-sheet-1 {
    min-height: 436px;
  }

  .u-section-4 .u-image-1 {
    width: 540px;
    height: 392px;
  }
}

@media (max-width: 575px) {
  .u-section-4 .u-sheet-1 {
    min-height: 291px;
  }

  .u-section-4 .u-image-1 {
    height: 247px;
    margin-top: 22px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }
}.u-section-5 {
  min-height: 827px;
}

.u-section-5 .u-layout-wrap-1 {
  width: calc(((100% - 1140px) / 2) + 1140px);
  margin: 60px auto 0 0;
}

.u-section-5 .u-layout-cell-1 {
  min-height: 257px;
  background-image: none;
}

.u-section-5 .u-container-layout-1 {
  padding: 30px;
}

.u-section-5 .u-layout-cell-2 {
  min-height: 257px;
}

.u-section-5 .u-container-layout-2 {
  padding: 30px;
}

.u-section-5 .u-line-1 {
  height: 3px;
  transform-origin: left center;
  margin: 0 0 0 auto;
}

.u-section-5 .u-text-1 {
  font-size: 2.25rem;
  margin: 20px 0 0;
}

.u-section-5 .u-layout-cell-3 {
  min-height: 257px;
}

.u-section-5 .u-container-layout-3 {
  padding: 30px 30px 26px;
}

.u-section-5 .u-text-2 {
  font-size: 1.25rem;
  margin: 20px 0 0;
}

.u-section-5 .u-layout-cell-4 {
  min-height: 257px;
}

.u-section-5 .u-container-layout-4 {
  padding: 30px;
}

.u-section-5 .u-image-1 {
  height: 180px;
  margin: 0 auto 0 0;
}

.u-section-5 .u-layout-wrap-2 {
  width: 753px;
  margin: 42px calc(((100% - 1140px) / 2)) 0 auto;
}

.u-section-5 .u-layout-cell-5 {
  min-height: 348px;
}

.u-section-5 .u-container-layout-5 {
  padding: 30px 30px 29px;
}

.u-section-5 .u-text-3 {
  font-size: 1.25rem;
  margin: 0;
}

.u-section-5 .u-image-2 {
  width: 450px;
  height: 581px;
  margin: -461px auto 0 0;
}

@media (max-width: 1199px) {
  .u-section-5 {
    min-height: 761px;
  }

  .u-section-5 .u-layout-wrap-1 {
    width: calc(((100% - 940px) / 2) + 940px);
    margin-top: 10px;
  }

  .u-section-5 .u-layout-cell-1 {
    min-height: 212px;
  }

  .u-section-5 .u-layout-cell-2 {
    min-height: 224px;
  }

  .u-section-5 .u-line-1 {
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-5 .u-text-1 {
    font-size: 1.875rem;
  }

  .u-section-5 .u-layout-cell-3 {
    min-height: 202px;
  }

  .u-section-5 .u-layout-cell-4 {
    min-height: 268px;
  }

  .u-section-5 .u-image-1 {
    height: 212px;
    width: 194px;
    margin-right: -7px;
    margin-bottom: -4px;
    margin-left: auto;
  }

  .u-section-5 .u-layout-wrap-2 {
    width: 940px;
    margin-right: auto;
  }

  .u-section-5 .u-layout-cell-5 {
    min-height: 347px;
  }

  .u-section-5 .u-text-3 {
    width: auto;
    margin-right: 23px;
    margin-left: 398px;
  }

  .u-section-5 .u-image-2 {
    width: 421px;
    margin-top: -487px;
  }
}

@media (max-width: 991px) {
  .u-section-5 {
    min-height: 883px;
  }

  .u-section-5 .u-layout-wrap-1 {
    margin-top: 0;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-5 .u-layout-cell-1 {
    min-height: 108px;
  }

  .u-section-5 .u-layout-cell-2 {
    min-height: 100px;
  }

  .u-section-5 .u-layout-cell-3 {
    min-height: 100px;
  }

  .u-section-5 .u-container-layout-3 {
    padding-bottom: 0;
  }

  .u-section-5 .u-text-2 {
    width: auto;
    margin-left: 30px;
  }

  .u-section-5 .u-layout-cell-4 {
    min-height: 100px;
  }

  .u-section-5 .u-image-1 {
    margin: -192px auto 0 -224px;
  }

  .u-section-5 .u-layout-wrap-2 {
    width: 720px;
    margin-top: 53px;
    margin-right: 63px;
    margin-left: calc(((100% - 720px) / 2) + -13px);
  }

  .u-section-5 .u-text-3 {
    margin-right: 0;
    margin-left: 236px;
  }

  .u-section-5 .u-image-2 {
    width: 316px;
    height: 436px;
    object-position: 100% 50%;
    margin-top: -347px;
  }
}

@media (max-width: 767px) {
  .u-section-5 {
    min-height: 927px;
  }

  .u-section-5 .u-layout-cell-1 {
    order: 3;
    min-height: 1215px;
  }

  .u-section-5 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-5 .u-layout-cell-2 {
    order: 1;
  }

  .u-section-5 .u-line-1 {
    transform-origin: left center 0px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-5 .u-text-1 {
    width: auto;
    margin-left: 20px;
  }

  .u-section-5 .u-layout-cell-3 {
    order: 2;
  }

  .u-section-5 .u-text-2 {
    margin-top: 0;
    margin-right: 37px;
    margin-left: 20px;
  }

  .u-section-5 .u-layout-cell-4 {
    order: 0;
  }

  .u-section-5 .u-container-layout-4 {
    padding-right: 10px;
  }

  .u-section-5 .u-image-1 {
    margin: 37px 0 -249px auto;
  }

  .u-section-5 .u-layout-wrap-2 {
    margin-top: 0;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-5 .u-layout-cell-5 {
    min-height: 507px;
  }

  .u-section-5 .u-container-layout-5 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-5 .u-text-3 {
    margin-top: 29px;
    margin-right: 37px;
    margin-left: 209px;
  }

  .u-section-5 .u-image-2 {
    width: 248px;
    margin-top: -455px;
    margin-bottom: 19px;
  }
}

@media (max-width: 575px) {
  .u-section-5 {
    min-height: 1364px;
  }

  .u-section-5 .u-layout-cell-1 {
    min-height: 140px;
  }

  .u-section-5 .u-text-1 {
    margin-left: 0;
  }

  .u-section-5 .u-container-layout-3 {
    padding-bottom: 20px;
  }

  .u-section-5 .u-text-2 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-5 .u-layout-cell-4 {
    min-height: 60px;
  }

  .u-section-5 .u-image-1 {
    margin-top: 30px;
    margin-right: -35px;
    margin-bottom: -242px;
  }

  .u-section-5 .u-layout-wrap-2 {
    min-height: 400px;
    width: 340px;
    margin-left: auto;
    margin-right: auto;
  }

  .u-section-5 .u-layout-cell-5 {
    min-height: 410px;
  }

  .u-section-5 .u-text-3 {
    margin-right: 0;
    margin-left: -10px;
  }

  .u-section-5 .u-image-2 {
    width: 368px;
    height: 437px;
    margin-top: -29px;
    margin-bottom: 0;
  }
}.u-section-6 {
  min-height: 667px;
}

.u-section-6 .u-layout-wrap-1 {
  width: calc(((100% - 1140px) / 2) + 1140px);
  margin: 5px 0 0 auto;
}

.u-section-6 .u-layout-cell-1 {
  min-height: 306px;
}

.u-section-6 .u-container-layout-1 {
  padding: 30px;
}

.u-section-6 .u-image-1 {
  width: 289px;
  height: 150px;
  margin: 0 auto 0 -24px;
}

.u-section-6 .u-layout-cell-2 {
  min-height: 306px;
}

.u-section-6 .u-container-layout-2 {
  padding: 30px;
}

.u-section-6 .u-line-1 {
  height: 3px;
  transform-origin: left center 0px;
  margin: 0 auto 0 0;
}

.u-section-6 .u-text-1 {
  font-size: 3rem;
  margin: 64px -23px 0 0;
}

.u-section-6 .u-layout-cell-3 {
  min-height: 306px;
}

.u-section-6 .u-container-layout-3 {
  padding: 30px 30px 26px;
}

.u-section-6 .u-text-2 {
  font-size: 1.25rem;
  margin: 48px 0 0;
}

.u-section-6 .u-text-3 {
  font-weight: 700;
  margin: 12px 282px 0 0;
}

.u-section-6 .u-btn-1 {
  padding: 0;
}

.u-section-6 .u-layout-cell-4 {
  min-height: 306px;
  background-image: none;
}

.u-section-6 .u-container-layout-4 {
  padding: 30px;
}

.u-section-6 .u-layout-wrap-2 {
  width: 1134px;
  margin: 25px calc(((100% - 1140px) / 2)) 5px auto;
}

.u-section-6 .u-layout-cell-5 {
  min-height: 326px;
}

.u-section-6 .u-container-layout-5 {
  padding: 30px;
}

.u-section-6 .u-text-4 {
  margin-top: 0;
  font-size: 1.25rem;
  margin-bottom: 0;
}

.u-section-6 .u-layout-cell-6 {
  min-height: 326px;
}

.u-section-6 .u-container-layout-6 {
  padding: 30px 30px 19px;
}

.u-section-6 .u-text-5 {
  font-size: 1.25rem;
  margin: 0 auto 0 27px;
}

.u-section-6 .u-image-2 {
  width: 159px;
  height: 306px;
  object-position: 100% 50%;
  margin: -286px -30px 0 auto;
}

@media (max-width: 1199px) {
  .u-section-6 {
    min-height: 665px;
  }

  .u-section-6 .u-layout-wrap-1 {
    width: calc(((100% - 940px) / 2) + 940px);
    margin-top: 0;
  }

  .u-section-6 .u-layout-cell-1 {
    min-height: 246px;
  }

  .u-section-6 .u-image-1 {
    width: 208px;
    height: 125px;
    margin-top: 15px;
    margin-right: -21px;
    margin-left: auto;
  }

  .u-section-6 .u-layout-cell-2 {
    min-height: 246px;
  }

  .u-section-6 .u-line-1 {
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-6 .u-text-1 {
    font-size: 1.875rem;
    margin-right: 0;
  }

  .u-section-6 .u-layout-cell-3 {
    min-height: 246px;
  }

  .u-section-6 .u-container-layout-3 {
    padding-bottom: 23px;
  }

  .u-section-6 .u-text-2 {
    font-size: 1.125rem;
    margin-top: 15px;
    margin-right: auto;
  }

  .u-section-6 .u-text-3 {
    width: auto;
    margin-top: 13px;
    margin-right: -88px;
  }

  .u-section-6 .u-layout-cell-4 {
    min-height: 246px;
  }

  .u-section-6 .u-layout-wrap-2 {
    width: 940px;
    margin-right: auto;
    margin-bottom: 13px;
  }

  .u-section-6 .u-layout-cell-5 {
    min-height: 381px;
  }

  .u-section-6 .u-layout-cell-6 {
    min-height: 381px;
  }

  .u-section-6 .u-text-5 {
    width: auto;
    margin-right: 128px;
    margin-left: 0;
  }

  .u-section-6 .u-image-2 {
    width: 135px;
  }
}

@media (max-width: 991px) {
  .u-section-6 {
    min-height: 930px;
  }

  .u-section-6 .u-layout-wrap-1 {
    width: calc(((100% - 720px) / 2) + 720px);
    margin-top: 1px;
  }

  .u-section-6 .u-layout-cell-1 {
    min-height: 208px;
  }

  .u-section-6 .u-image-1 {
    margin-top: 0;
    margin-right: auto;
    margin-left: 31px;
  }

  .u-section-6 .u-layout-cell-2 {
    min-height: 170px;
  }

  .u-section-6 .u-layout-cell-3 {
    min-height: 100px;
  }

  .u-section-6 .u-container-layout-3 {
    padding-bottom: 4px;
  }

  .u-section-6 .u-text-3 {
    width: 424px;
    margin-right: 545px;
  }

  .u-section-6 .u-layout-cell-4 {
    min-height: 64px;
  }

  .u-section-6 .u-layout-wrap-2 {
    width: 720px;
    margin-top: 15px;
    margin-bottom: 1px;
  }

  .u-section-6 .u-layout-cell-5 {
    min-height: 100px;
  }

  .u-section-6 .u-layout-cell-6 {
    min-height: 508px;
  }

  .u-section-6 .u-text-5 {
    margin-right: 0;
  }

  .u-section-6 .u-image-2 {
    width: 142px;
    height: 222px;
    margin-top: 0;
    margin-right: 62px;
    margin-bottom: -19px;
  }
}

@media (max-width: 767px) {
  .u-section-6 {
    min-height: 1132px;
  }

  .u-section-6 .u-layout-wrap-1 {
    margin-top: 0;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-6 .u-layout-cell-1 {
    order: 0;
    min-height: 185px;
  }

  .u-section-6 .u-container-layout-1 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .u-section-6 .u-image-1 {
    width: 286px;
    margin-left: auto;
  }

  .u-section-6 .u-layout-cell-2 {
    min-height: 144px;
    order: 1;
  }

  .u-section-6 .u-container-layout-2 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .u-section-6 .u-text-1 {
    margin-top: 15px;
  }

  .u-section-6 .u-layout-cell-3 {
    order: 2;
  }

  .u-section-6 .u-container-layout-3 {
    padding-left: 50px;
    padding-right: 50px;
  }

  .u-section-6 .u-text-2 {
    width: auto;
    margin-top: -16px;
    margin-right: -7px;
  }

  .u-section-6 .u-text-3 {
    width: auto;
    margin-top: 15px;
    margin-right: 66px;
  }

  .u-section-6 .u-layout-cell-4 {
    order: 3;
    min-height: 1440px;
  }

  .u-section-6 .u-container-layout-4 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-6 .u-layout-wrap-2 {
    width: 540px;
    margin-bottom: 0;
  }

  .u-section-6 .u-container-layout-5 {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0;
  }

  .u-section-6 .u-layout-cell-6 {
    min-height: 331px;
  }

  .u-section-6 .u-container-layout-6 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-6 .u-text-5 {
    margin-right: 163px;
    margin-left: 44px;
  }

  .u-section-6 .u-image-2 {
    height: 223px;
    margin-top: -223px;
    margin-right: 0;
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .u-section-6 {
    min-height: 1347px;
  }

  .u-section-6 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-6 .u-container-layout-1 {
    padding-right: 10px;
    padding-bottom: 50px;
  }

  .u-section-6 .u-layout-cell-2 {
    min-height: 100px;
  }

  .u-section-6 .u-container-layout-3 {
    padding-bottom: 20px;
    padding-left: 30px;
  }

  .u-section-6 .u-text-2 {
    margin-right: -5px;
  }

  .u-section-6 .u-text-3 {
    width: 424px;
    margin-right: 0;
  }

  .u-section-6 .u-layout-cell-4 {
    min-height: 187px;
  }

  .u-section-6 .u-layout-wrap-2 {
    width: 340px;
    margin-right: calc(((100% - 340px) / 2));
    margin-left: calc(((100% - 340px) / 2));
    min-height: 400px;
  }

  .u-section-6 .u-layout-cell-6 {
    min-height: 612px;
  }

  .u-section-6 .u-text-5 {
    margin-right: 100px;
    margin-left: 27px;
  }

  .u-section-6 .u-image-2 {
    margin-top: 20px;
    margin-right: 100px;
  }
}.u-section-7 {
  background-image: url("images/fondo3.jpg");
  background-position: 50% 50%;
}

.u-section-7 .u-sheet-1 {
  min-height: 776px;
}

.u-section-7 .u-layout-wrap-1 {
  width: 809px;
  margin: 20px auto 30px;
}

.u-section-7 .u-layout-cell-1 {
  min-height: 374px;
}

.u-section-7 .u-container-layout-1 {
  padding: 0 30px 30px;
}

.u-section-7 .u-image-1 {
  height: 343px;
  margin: 0 auto;
}

.u-section-7 .u-layout-cell-2 {
  min-height: 374px;
}

.u-section-7 .u-container-layout-2 {
  padding: 0 30px 30px;
}

.u-section-7 .u-image-2 {
  height: 343px;
  margin: 0 0 0 auto;
}

.u-section-7 .u-layout-cell-3 {
  min-height: 374px;
}

.u-section-7 .u-container-layout-3 {
  padding: 0 30px 30px;
}

.u-section-7 .u-image-3 {
  height: 343px;
  margin: 0 0 0 auto;
}

.u-section-7 .u-layout-cell-4 {
  min-height: 352px;
}

.u-section-7 .u-container-layout-4 {
  padding: 0 30px 30px;
}

.u-section-7 .u-image-4 {
  width: 195px;
  height: 318px;
  margin: 0 0 0 auto;
}

.u-section-7 .u-layout-cell-5 {
  min-height: 352px;
}

.u-section-7 .u-container-layout-5 {
  padding: 0 30px 30px;
}

.u-section-7 .u-image-5 {
  width: 194px;
  height: 317px;
  margin: 0 auto;
}

.u-section-7 .u-layout-cell-6 {
  min-height: 352px;
}

.u-section-7 .u-container-layout-6 {
  padding: 0 30px 30px;
}

.u-section-7 .u-image-6 {
  width: 240px;
  height: 318px;
  margin: 0 -15px;
}

@media (max-width: 1199px) {
  .u-section-7 .u-sheet-1 {
    min-height: 762px;
  }

  .u-section-7 .u-layout-wrap-1 {
    margin-top: 8px;
  }

  .u-section-7 .u-layout-cell-1 {
    min-height: 238px;
  }

  .u-section-7 .u-image-1 {
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-7 .u-layout-cell-2 {
    background-position: 50% 50%;
  }

  .u-section-7 .u-image-2 {
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-7 .u-layout-cell-3 {
    background-position: 100% 50%;
  }

  .u-section-7 .u-image-3 {
    height: 342px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-7 .u-layout-cell-4 {
    min-height: 351px;
  }

  .u-section-7 .u-layout-cell-5 {
    min-height: 351px;
  }

  .u-section-7 .u-layout-cell-6 {
    min-height: 351px;
  }

  .u-section-7 .u-image-6 {
    margin-right: auto;
    margin-left: -30px;
  }
}

@media (max-width: 991px) {
  .u-section-7 .u-sheet-1 {
    min-height: 100vh;
  }

  .u-section-7 .u-layout-wrap-1 {
    margin-bottom: 49px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-7 .u-layout-cell-1 {
    min-height: 324px;
  }

  .u-section-7 .u-image-1 {
    height: 294px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-7 .u-layout-cell-2 {
    min-height: 324px;
  }

  .u-section-7 .u-image-2 {
    height: 294px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-7 .u-layout-cell-3 {
    min-height: 324px;
  }

  .u-section-7 .u-image-3 {
    height: 294px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-7 .u-layout-cell-4 {
    min-height: 324px;
  }

  .u-section-7 .u-image-4 {
    width: 180px;
    height: 294px;
  }

  .u-section-7 .u-layout-cell-5 {
    min-height: 324px;
  }

  .u-section-7 .u-image-5 {
    width: 180px;
    height: 294px;
  }

  .u-section-7 .u-layout-cell-6 {
    min-height: 324px;
  }

  .u-section-7 .u-image-6 {
    width: 222px;
    height: 294px;
    margin-left: -42px;
  }
}

@media (max-width: 767px) {
  .u-section-7 .u-sheet-1 {
    min-height: 400px;
  }

  .u-section-7 .u-layout-wrap-1 {
    margin-bottom: 0;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-7 .u-layout-cell-1 {
    min-height: 514px;
  }

  .u-section-7 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-7 .u-image-1 {
    height: 400px;
    width: 236px;
    margin-right: auto;
    margin-left: 0;
  }

  .u-section-7 .u-layout-cell-2 {
    min-height: 873px;
  }

  .u-section-7 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-7 .u-image-2 {
    height: 443px;
    width: 284px;
    margin-top: 59px;
    margin-right: 38px;
    margin-left: auto;
  }

  .u-section-7 .u-layout-cell-3 {
    min-height: 947px;
  }

  .u-section-7 .u-container-layout-3 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-7 .u-image-3 {
    height: 367px;
    width: 224px;
    margin-top: 231px;
    margin-left: auto;
    margin-right: auto;
  }

  .u-section-7 .u-layout-cell-4 {
    min-height: 100px;
  }

  .u-section-7 .u-container-layout-4 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-7 .u-layout-cell-5 {
    min-height: 410px;
  }

  .u-section-7 .u-container-layout-5 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-7 .u-image-5 {
    margin-top: 23px;
  }

  .u-section-7 .u-layout-cell-6 {
    min-height: 410px;
  }

  .u-section-7 .u-container-layout-6 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-7 .u-image-6 {
    margin-top: 23px;
    margin-left: auto;
  }
}

@media (max-width: 575px) {
  .u-section-7 .u-sheet-1 {
    min-height: 776px;
  }

  .u-section-7 .u-layout-wrap-1 {
    position: relative;
    min-height: 324px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-7 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-7 .u-layout-cell-2 {
    min-height: 100px;
  }

  .u-section-7 .u-image-2 {
    height: 444px;
    margin-right: 36px;
  }

  .u-section-7 .u-layout-cell-3 {
    min-height: 100px;
  }

  .u-section-7 .u-image-3 {
    height: 366px;
  }

  .u-section-7 .u-layout-cell-5 {
    min-height: 100px;
  }

  .u-section-7 .u-layout-cell-6 {
    min-height: 100px;
  }
}.u-section-8 {
  background-image: none;
}

.u-section-8 .u-sheet-1 {
  min-height: 417px;
}

.u-section-8 .u-layout-wrap-1 {
  margin: 67px 0 0 auto;
}

.u-section-8 .u-layout-cell-1 {
  min-height: 350px;
}

.u-section-8 .u-container-layout-1 {
  padding: 20px;
}

.u-section-8 .u-form-1 {
  margin: 0;
}

.u-section-8 .u-form-group-1 {
  margin-left: auto;
}

.u-section-8 .u-input-1 {
  background-image: none;
}

.u-section-8 .u-form-group-2 {
  margin-left: auto;
}

.u-section-8 .u-input-2 {
  background-image: none;
}

.u-section-8 .u-form-group-3 {
  margin-left: auto;
}

.u-section-8 .u-input-3 {
  background-image: none;
}

.u-section-8 .u-form-group-4 {
  margin-left: auto;
}

.u-section-8 .u-input-4 {
  background-image: none;
}

.u-section-8 .u-form-group-5 {
  margin-left: auto;
}

.u-section-8 .u-input-5 {
  background-image: none;
}

.u-section-8 .u-form-group-6 {
  margin-left: auto;
}

.u-section-8 .u-btn-1 {
  margin-left: auto;
  background-image: none;
  border-style: none;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
}

.u-section-8 .u-layout-cell-2 {
  min-height: 350px;
}

.u-section-8 .u-container-layout-2 {
  padding: 20px 60px 20px 20px;
}

.u-section-8 .u-text-1 {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
}

.u-section-8 .u-text-2 {
  margin-bottom: 0;
  margin-left: auto;
  font-size: 3.75rem;
  font-weight: 700;
}

.u-section-8 .u-text-3 {
  margin-left: auto;
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  .u-section-8 .u-sheet-1 {
    min-height: 373px;
  }

  .u-section-8 .u-layout-wrap-1 {
    margin-top: 26px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-8 .u-layout-cell-1 {
    min-height: 100px;
    background-position: 50% 50%;
  }

  .u-section-8 .u-layout-cell-2 {
    min-height: 100px;
  }

  .u-section-8 .u-text-1 {
    position: relative;
  }

  .u-section-8 .u-text-2 {
    position: relative;
  }

  .u-section-8 .u-text-3 {
    position: relative;
  }
}

@media (max-width: 991px) {
  .u-section-8 .u-sheet-1 {
    min-height: 367px;
  }

  .u-section-8 .u-layout-wrap-1 {
    margin-top: 0;
    margin-bottom: 20px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-8 .u-layout-cell-2 {
    min-height: 347px;
  }

  .u-section-8 .u-container-layout-2 {
    padding-right: 30px;
  }

  .u-section-8 .u-text-1 {
    margin-left: 0;
  }

  .u-section-8 .u-text-2 {
    font-size: 3rem;
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-8 .u-text-3 {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .u-section-8 .u-sheet-1 {
    min-height: 425px;
  }

  .u-section-8 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-8 .u-layout-cell-2 {
    min-height: 100px;
  }

  .u-section-8 .u-container-layout-2 {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (max-width: 575px) {
  .u-section-8 .u-form-group-1 {
    width: 100%;
  }

  .u-section-8 .u-form-group-2 {
    width: 100%;
  }

  .u-section-8 .u-form-group-3 {
    width: 100%;
  }

  .u-section-8 .u-form-group-4 {
    width: 100%;
  }

  .u-section-8 .u-form-group-5 {
    width: 100%;
  }

  .u-section-8 .u-form-group-6 {
    width: 100%;
  }
}