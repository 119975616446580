body {
  overflow: hidden;
  margin: 0 !important;

  * {
    font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif !important;
  }

  img {
    user-select: none;
    -webkit-user-drag: none;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

.ant-spin-nested-loading,
.ant-spin-nested-loading>div,
.ant-spin-nested-loading .ant-spin {
  height: inherit;
}

.ant-spin-nested-loading>div {
  height: inherit;
}

.ant-spin-nested-loading .ant-spin {
  max-height: 100vh !important;
}

.ant-spin-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}

.ant-menu * {
  user-select: none;
}

.ant-input-group.lada-num {
  .ant-row.ant-form-item:nth-child(1) .ant-col.ant-form-item-control * {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  .ant-row.ant-form-item:nth-child(2) .ant-col.ant-form-item-control * {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .ant-form-item-label {
    display: block;
  }
}

.ant-input,
.ant-input-affix-wrapper,
.ant-select-selector,
.ant-input-number,
.ant-picker {
  border-radius: 4px !important;
}

.ant-form-item-label label {
  font-weight: bold;
}

.ant-form-vertical .ant-form-item-label {
  padding: 0 !important;

  label {
    height: 22px;
  }
}

.ant-form-item-has-error {
  .ant-input-affix-wrapper,
  .ant-input-number {
    border-color: #ff4949 !important;
    z-index: 2;
  }
  .ant-form-item-explain {
    font-size: 12px;
  }
}

.wrap-text {
	.ant-select-item-option-content {
		word-break: break-word !important;
		text-overflow: unset !important;
		white-space: unset !important;
	}
}